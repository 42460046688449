
.notification-data{
  display: flex;
  flex-direction: column;
  .mountainList,.notification-item,.calendar{
    width: 200px;
  }
}

.calendar{
  .calendar-button{
    border: 2px solid lightGray !important;
    height: 36px;
    align-items: center;
    padding: 0 12px !important;
    .calendar-text,.calendar-selected-text{
      margin: 0;
    }
  }
}

.mountainList{
  .ant-select{
    width: 100%;
    border:2px solid lightGray !important;
    .ant-select-selection--single{
      border: unset;
    }
  }
}

.notification-item{
  .ant-select-selection--multiple{
    border: unset !important;
  }
}

.notification-item{
  .ant-select{
    width: 100%;
    border:2px solid lightGray !important;
    .ant-select-selection--single{
      border: unset;
    }
  }
}

.warning-message{
  color: #f5222d;
  font-size: 14px;
  line-height: 22px;
}

.notification-component{
  padding-bottom: 100px;
}

.required-remainder{
  color: #f5222d;
  margin-bottom: 7px;
}

.time-label{
  color: rgba(0, 0, 0, 0.85)
}

.time-item .ant-form-item{
  margin-bottom: 0;
}

.time-item{
  margin-bottom: 20px;
}

.notification-header{
  display: flex;
  justify-content: space-between;
}

.view-notification-image{
  width: 70px;
}

#snowgroup_login_datePicker{
  width: 100%;
  border: 2px solid lightGray;
  input{
    border: unset !important;
  }
}

input{
  border:2px solid lightGray !important;
  border-radius: unset !important;
}
.ant-form-item-children input:hover{
  border-right-width: 2px !important;
}
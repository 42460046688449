.search-part{
  display: flex;
  align-items: flex-end;
  .ant-form-item{
    margin-bottom: 0;
  }
  button{
    margin:0 0 4px 10px;
  }
}

.add-notification-item{
  width: 200px;
  margin-bottom: 10px !important;
  .ant-form-item-label{
    line-height: 25px !important;
  }
  label{
    margin-bottom: 0 !important;
  }
}

.notification-url{
  input{
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px !important;
  }
}
.spinner-container{
    position: fixed;
    width: 100vw;
    z-index: 10000;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.562);
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
}

.spinner-ticket-container{
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
}
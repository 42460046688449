@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body{
    font-family: 'Roboto', sans-serif;

    .markdown-header{
        font-size: 24px;
        line-height: 32px;
        clear: both;
        margin: 0em 0 .6em;
        color: #0d1a26;
        font-weight: 500;
        font-family: Avenir,-apple-system,BlinkMacSystemFont,'Segoe UI','PingFang SC','Hiragino Sans GB','Microsoft YaHei','Helvetica Neue',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol',sans-serif;
    }
    h3.markdown-header{
        font-size: 18px;
    }
}
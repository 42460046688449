.ticket-view-container{
    .payment-container{
        margin-top: 50px;
    }
    .general-information-table-container{
        border: 1px solid #e8e8e8;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .general-information-col{
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            align-items: center;
            transition: all 0.3s, height 0s;
            width: 100%;
            &:last-child{
                border-bottom: 0;
            }
            &:hover{
                background: #e6f7ff;
            }
            p{
                height: 40px;
                width: 50%;
                padding-left: 10px;
                margin: 0;
                display: flex;
                align-items: center;
                &:first-child{
                    border-right: 1px solid #e8e8e8;
                }
                &.information-tab{
                    font-weight: 600;
                }
            }
        }
    }
    .passengers-information-heading-container{
        margin-top: 50px;
    }
    .ticket-view-header{
        display: flex;
        justify-content: space-between;
        .action-buttons{
            display: flex;
            align-items: center;
            button{
                margin-right: 10px;
            }
        }
    }

}

.user-view-header{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.user-view-name{
    width: 30% !important;
}

.button-collection{
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.dicounts{
    margin-top: 10px;
}